html, body {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}

* {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -500px 0
    }
    100%{
        background-position: 500px 0
    }
}
.animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
}

#root {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}

.main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.left-col {
    display: flex;
    flex-grow: 1;
}
.right-col {
    display: flex;
    min-width: 500px;
    max-width: 500px;
}








.side-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-left: solid 5px rgba(52, 168, 235, 1);
    background-color: rgba(255, 255, 255, 1);
}
.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 100%;
    background-color: rgba(52, 168, 235, 1);
    width: 150px;
    height: 150px;
    font-size: 100px;
    color: rgba(255, 255, 255, 1);
}
.location-name {
    font-size: 30px;
    font-weight: 100;
    text-transform: uppercase;
}
.location-address {
    font-weight: 100;
    font-size: 14px;
}
.location-name.animated-background {
    width: 100px;
    height: 25px;
}
.location-address.animated-background {
    margin-top: 5px;
    width: 250px;
    height: 20px;
}

.date-and-time-container {
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    margin: 40px 20px;
}
.date-and-time-container .time {
    margin-right: 10px;
    font-weight: 500;
    font-size: 32px;
}
.date-and-time-container .date {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 300;
}


.weather-outer-container {
    padding-left: 20px;
    padding-right: 20px;
}
.weather-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.forecast-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 10px;
    background-color: rgba(52, 168, 235, 0.1);
}
.forecast-time-and-temperature {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.forecast-time {
    font-weight: 100;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
}
.forecast-temperature {
    font-weight: 100;
    font-size: 22px;
    color: rgba(52, 168, 235, 1);
    text-align: right;
}
.forecast-feels-like,
.forecast-wind {
    font-weight: 100;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
}
.forecast-feels-like > span {
    font-weight: 700;
}
.forecast-details {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    color: rgba(52, 168, 235, 1);
}
.forecast-details > img {
    width: 60px;
    margin-right: 10px;
}
.forecast-description {
    margin-top: 10px;
    font-weight: 100;
    font-size: 12px;
    text-transform: uppercase;
    text-align: right;
}







.lines-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.05);
}
.lines-container-super-wide .line-container-outer {
    width: 500px;
}
.lines-container-outer-smaller .line-container-outer {
    width: 300px;
}
.lines-container.no-results {
    justify-content: center;
    align-items: center;
}
.lines-container.no-results > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    font-weight: 300;
}
.line-container-outer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 350px;
    height: 50%;
    overflow: hidden;
}
.line-container {
    flex-grow: 1;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    overflow-x: hidden;
}

.line-header {
    display: flex;
    align-items: center;
}
.line-icon {
    margin-right: 10px;
}
.line-icon > img {
    width: 50px;
}
.line-icon > .animated-background {
    width: 50px;
    height: 50px;
    border-radius: 100px;
}
.line-title {
    margin: 0;
    padding: 0;
    font-size: 36px;
    color: rgba(0, 0, 0, 0.7);
}
.line-title.animated-background {
    width: 100%;
    height: 100%;
}



.departures-container {
    margin-top: 20px;
}
.departure-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 10px;
    border-left: solid 5px rgba(0, 0, 0, 0.2);
}
.departure-container:last-of-type {
    margin-bottom: 0;
}
.departure-direction-container {
    margin-right: 10px;
}
.departure-direction-title {
    font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
}
.departure-direction {
    font-size: 28px;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.75);
}
.departure-direction.animated-background {
    width: 230px;
    height: 30px;
}
.departure-platform-container {
    margin-top: 20px;
}
.departure-platform {
    font-size: 28px;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.75);
}
.departure-platform.animated-background {
    width: 230px;
    height: 30px;
}
.departure-times {
    min-width: 80px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 100;
    text-align: left;
    color: rgba(0, 0, 0, 0.75);
}
.departure-time.animated-background {
    min-width: 40px;
    height: 15px;
}



@media screen and (max-width: 1366px) {
    .right-col {
        display: flex;
        min-width: 350px;
        max-width: 350px;
    }

    .logo-badge {
        width: 100px;
        height: 100px;
        font-size: 70px;
    }

    .date-and-time-container {
        margin: 10px;
    }

    .forecast-item {
        width: 120px;
        height: 120px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 5px;
    }
    .forecast-time {
        font-size: 18px;
    }
    .forecast-temperature {
        font-size: 18px;
    }
    .forecast-details {
        margin-top: 10px;
    }
    .forecast-details > img {
        width: 40px;
        margin-right: 10px;
    }








    .lines-container {
        padding: 10px;
    }
    .line-container-outer {
        width: 300px;
    }
    .line-container {
        margin: 10px;
        padding: 15px;
    }


    .line-icon > img {
        width: 40px;
    }
    .line-icon > .animated-background {
        width: 40px;
        height: 40px;
    }
    .line-title {
        font-size: 30px;
    }



    .departures-container {
        margin-top: 10px;
    }
    .departure-container {
        margin-bottom: 15px;
        padding-left: 10px;
    }
    .departure-direction-container {
        margin-right: 10px;
    }
    .departure-direction {
        font-size: 22px;
    }
    .departure-direction.animated-background {
        width: 230px;
        height: 30px;
    }
    .departure-platform-container {
        margin-top: 5px;
    }
    .departure-platform {
        font-size: 22px;
    }
    .departure-platform.animated-background {
        width: 230px;
        height: 30px;
    }
    .departure-times {
        font-size: 16px;
    }
    .departure-time.animated-background {
        min-width: 40px;
        height: 15px;
    }
}
